<template>
  <div id="Search-view" class="my-5">
    <div class="container-fluid" v-if="!Singleproduct">
      <center>
        <div class="findgft">
          <h1 class="display-1 mb-0" style="font-weight: 400">
            <span>
              <img
                class="giftbox-img mr-2"
                src="@/assets/site/images/gift-box-left.svg"
                alt=""
            /></span>
            gifts
            <span>
              <img
                class="giftbox-img ml-2"
                src="@/assets/site/images/gift-box-right.svg"
                alt=""
            /></span>
          </h1>
        </div>
        <div class="mt-5 mb-5" v-if="keyword == 'gift card'">
          <b-input-group class="mt-3 col-md-6">
            <b-form-input
              v-model="giftCardKeyword"
              @keyup.enter="giftCard_search"
              placeholder="Enter a keyword"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="btn btn-info"
                @click="giftCard_search"
                v-if="!search_load"
                >Search</b-button
              >
              <b-button variant="buttonload" v-if="search_load"
                ><i class="fa fa-refresh fa-spin"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </center>
      <div class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <!-- <div v-if="products.length < 1" class="row"> -->
          <center v-if="products.length < 1" class="mt-4">
            <h3>
              <strong class="text-danger mx-3"
                >There are no Gifts based on your keywords,<br />
                please refine your search!!!</strong
              >
            </h3>
          </center>
          <!-- </div> -->
          <div class="row">
            <div class="col-md-3" v-for="product in products">
              <div
                class="owl-item active wizard-box"
                :id="'product_' + product.sku"
              >
                <div
                  @click="ViewProduct(product)"
                  class="item product product-item"
                >
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right">
                        <!-- <b-button size="md" variant="outline-danger" @click="savegift(product)" class="fa fa-gift gift-icon px-3"
                          v-b-popover.hover.top="'Gift'"></b-button> -->
                      </div>
                      <a class="product photo product-item-photo" tabindex="-1">
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <span v-if="product.imageurl">
                              <img
                                class="wizard-image-photo lazyload"
                                :src="product.imageurl"
                                @error="() => (product.imageurl = null)"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt=""
                            /></span>
                            <span v-else>
                              <img
                                class="wizard-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt=""
                            /></span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          data-post=""
                          @click.stop="savetoFavourite(product)"
                          class="action towishlist btn-action link-wishlist"
                          data-action="add-to-favourite"
                          title="Add to Favourite"
                        >
                          <span>Add to Favourite</span>
                        </a>
                        <a
                          data-post=""
                          @click.stop="selectOccasion(product)"
                          class="action tocart primary btn-action btn-cart"
                          data-action="add-to-wishlist"
                          title="Add to WishList"
                        >
                          <span>Add to Wish List</span>
                        </a>
                        <a
                          class="action tocompare btn-action link-compare"
                          @click.stop="compareProduct(product)"
                          data-post=""
                          title="Add to Compare"
                        >
                          <span>Add to Compare</span>
                        </a>
                        <a
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click.stop="ViewProduct(product)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ product.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                          >
                            <span v-if="product.price != null" class="price">
                              USD {{ product.price }}
                            </span>
                            <span v-else class="price">
                              {{ product.price["@currency"] }}
                              {{ product.price["#text"] }}
                            </span>
                            <span class="price">
                              {{ product.price["@currency"] }}
                              {{ product.price["#text"] }}
                            </span>
                          </span>
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="page != totalPages && products.length >= 1"
          class="row my-4 d-flex justify-content-between"
        >
          <div class="col-md-12">
            <button
              class="btn btn-danger float-right col-md-2"
              @click="keword_search"
            >
              More <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- <single-product
        v-if="Singleproduct"
        v-on:showAllProducts="showAllProducts"
        v-bind:sproduct="sproduct"
      ></single-product> -->
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
    </div>
  </div>
</template>
<script>
// import SingleProduct from "@/views/site/search/SearchProduct";
import OccasionModal from "@/views/site/OccasionModal";

export default {
  name: "SearchView",
  data() {
    return {
      loader: false,
      errors: [],
      sproduct: [],
      show: false,
      keyword: null,
      products: [],
      Singleproduct: false,
      category: "",
      page: 1,
      totalPages: null,
      wishlist_product: null,
      request_body: null,
      giftCardKeyword: null,
      search_load: false,
    };
  },
  components: {
    //  SingleProduct
    OccasionModal,
  },
  created() {
    this.$store.state.wizard_type = "User";
    this.getKeyword();
  },
  methods: {
    getKeyword() {
      this.keyword = this.$store.state.keyword;
      this.page = this.$store.state.page;
      this.request_body = this.$store.state.request_body;

      if (this.$store.state.products) {
        this.products = this.$store.state.products;
      }
      this.category = this.$store.state.category;
      this.totalPages = this.$store.state.totalPages;
    },
    ViewProduct(product) {
      this.$store.state.single_product = product;
      this.$router.push({
        name: "SearchSingle",
        params: {},
      });
      this.sproduct = product;
    },
    // showAllProducts() {
    //   this.Singleproduct = false;
    // },
    keword_search() {
      this.search_load = true;
      var cu_products = this.products;
      var page = parseInt(this.page + 1);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: this.keyword,
          category: this.category,
          page: page,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          this.products = cu_products.concat(data.response.item);
          this.$store.state.products = this.products;
          this.$store.state.page = page;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
      this.page = page;
    },
    selectOccasion(product) {
      this.wishlist_product = product;
      this.$bvModal.show("occasion-modal");
    },
    savetoWishlist(occasion) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        //Save or update User Details
        this.$bvModal.hide("occasion-modal");
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: this.wishlist_product.productId,
            //product_name:product.productName,
            product: this.wishlist_product,
            //price:product.price.text,
            user_id: user_id,
            clickstream_data: null,
            user_action: "AddToWishlist",
            wishlist_type: "User",
            occasion_id: occasion.id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;

              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
        this.wishlist_product = null;
      }
    },
    savetoFavourite(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        //Save or update User Details
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            product: product,
            user_id: user_id,
            clickstream_data: null,
            user_action: "AddToFav",
            // wishliist_occasionId: this.wizard_data.occasion,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;

              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.$swal.fire("Error", data.message, "error");
            } else {
              // this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    compareProduct(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        alert("Please log in to continue");
      } else {
        //Save or update User Details
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        console.log(user_id);
        var productId = product.mid + "-" + product.linkid;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: productId,
            //product_name:product.productName,
            product: product,
            //price:product.price.text,
            user_id: user_id,
            // clickstream_data:this.wizard_data,
            user_action: "compare",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    giftCard_search() {
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: "gift card " + this.giftCardKeyword,
          type: "gift_card",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          //  this.loader = false;
          var products = data.response;
          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.keyword = "gift card";
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
          // this.search_word = null;
          // this.selected_category = '';
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
